import React from 'react';
import Field from 'titus-ts/dist/js/MetadataRenderer/ClassificationSelector/Field';
import {
    ChoiceGroup,
    IChoiceGroupOption,
    IChoiceGroupOptionProps,
    IChoiceGroupOptionStyles,
    IChoiceGroupStyles,
} from '@fluentui/react/lib/ChoiceGroup';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';

// Custom renderer for field values (options) within fluent UI dropdown
export const _onRenderChoiceGroupLabel = (
    option: (IChoiceGroupOption & IChoiceGroupOptionProps & { data?: { color: string; tooltip: string } }) | undefined,
) => {
    const hasBackgroundColour = option?.data && option?.data.color;
    const hasToolTip = option?.data && option?.data.tooltip;
    const noTooltip = !option?.data || !option?.data.tooltip;
    return (
        <div
            style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                marginLeft: '30px',
            }}
        >
            {hasBackgroundColour && (
                <span
                    style={{
                        height: 10,
                        width: 10,
                        borderRadius: '50%',
                        backgroundColor: option.data?.color,
                        display: 'inline-block',
                        marginRight: '4px',
                    }}
                />
            )}
            {hasToolTip && (
                <TooltipHost id={option.key + '_' + option.text} content={option.data?.tooltip}>
                    <span>{option.text}</span>
                </TooltipHost>
            )}
            {noTooltip && <span>{option?.text}</span>}
        </div>
    );
};

type RadioButtonValuePickerProps = {
    field: Field;
    onChange: (fieldName: string, fieldValues: string | string[]) => void;
};

const ValuePickerRadioButtons = (props: RadioButtonValuePickerProps) => {
    const { field, onChange } = props;

    const selectedValue = field.selectedValue && field.selectedValue[0] ? field.selectedValue[0] : '';

    const options: IChoiceGroupOption[] = field.values.map((val) => {
        return {
            'data-testId': `${field.name}_${val.name}`,
            id: `${field.name}_${val.name}`,
            key: val.name,
            text: val.displayText,
            data: {
                color: val.colorCode,
                tooltip: val.tooltip,
            },
            onRenderLabel: _onRenderChoiceGroupLabel,
            //Title is used by MSFT as Tooltip, if not assigned any value item.text is used by default.
            title: '',
            styles: {
                choiceFieldWrapper: { width: '100%' },
                field: { maxWidth: '100%' },
            } as Partial<IChoiceGroupOptionStyles>,
        };
    });

    const _onChoiceGroupChange = (
        event: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
        item: IChoiceGroupOption | undefined,
    ): void => {
        onChange(field.name, item ? item.key : '');
    };

    return (
        <div data-testid="radio_valuepicker">
            <ChoiceGroup
                options={options}
                onChange={_onChoiceGroupChange}
                selectedKey={selectedValue}
                styles={
                    {
                        flexContainer: { width: '300px', overflow: 'hidden' },
                    } as Partial<IChoiceGroupStyles>
                }
            />
        </div>
    );
};

export default ValuePickerRadioButtons;
